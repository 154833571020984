<template>
    <div class="card mb-4">
        <!-- Notifications -->
        <div class="card-header">
            <h5 class="card-title">Powiadomienia</h5>
            <span class="card-subtitle">Wybierz z dostępnych powiadomień.</span>
        </div>
        <div class="table-responsive">
            <table class="table border-top table-striped">
                <thead>
                    <tr>
                        <th class="text-nowrap">Type</th>
                        <th class="text-nowrap text-center">🖥 Browser</th>
                        <th class="text-nowrap text-center">✉️ Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="module, index in acl" :key="index">
                        <td class="text-nowrap">{{ module.name }}</td>
                        <td v-for="input, index2 in module.items" :key="index2">
                            <div class="form-check d-flex justify-content-center">
                                <input @change="save" v-model="input.checked" class="form-check-input" type="checkbox">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- /Notifications -->
    </div>
</template>
<script>
import axios from '../../../api/axios';


export default {
    props: ['user_id'],
    created: function () {
        this.getNotifications();
    },
    methods: {
        save() {
            axios.put('api/v1/user/users/' + this.user_id + '/notifications', { acl: this.acl }).then(response => {
                console.log(response);
            });
        },
        getNotifications() {
            axios.get('api/v1/user/users/' + this.user_id + '/notifications').then(response => {
                this.acl = response.data.acl;
                this.modules = response.data.modules;
            })
        },
    },
    data: function () {
        return {
            acl: [],
            modules: [],
        }
    }
}
</script>