<template>
    <div class="modal fade" id="editUser" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-simple modal-edit-user">
            <div class="modal-content p-3 p-md-5">
                <div class="modal-body">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="text-center mb-4">
                        <h3>Edytuj dane użytkownika</h3>

                    </div>
                    <form id="formAccountSettings" method="POST" onsubmit="return false"
                        class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                        <div class="row">
                            <div class="mb-3 col-md-6 fv-plugins-icon-container">
                                <label for="firstName" class="form-label">Imię i nazwisko</label>
                                <input v-model="user.name" class="form-control" type="text" id="firstName" name="name"
                                    autofocus="">
                                <div class="fv-plugins-message-container invalid-feedback"></div>
                            </div>

                            <div class="mb-3 col-md-6">
                                <label for="email" class="form-label">E-mail</label>
                                <input v-model="user.email" class="form-control" type="email" id="email" name="email">
                            </div>

                            <div class="mb-3 col-md-6">
                                <label for="organization" class="form-label">Grupa użytkownika</label>
                                <select v-model="group" type="text" class="form-control" id="organization"
                                    name="organization" value="ThemeSelection">
                                    <option v-for="group, index in $store.state.slownik_groups" :value="index" :key="index">
                                        {{
                                            group.title }}</option>
                                </select>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label class="form-label" for="phoneNumber">Telefon</label>
                                <div class="input-group input-group-merge">
                                    <span class="input-group-text">+48</span>
                                    <input v-model="user.phone_number" type="text" id="phoneNumber" name="phoneNumber"
                                        class="form-control" placeholder="202 555 0111">
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <button type="submit" @click.prevent="onSave" class="btn btn-primary me-2">Zapisz</button>
                            <button type="reset" @click.prevent="onCancel" class="btn btn-label-secondary">Anuluj</button>
                        </div>
                        <input type="hidden">
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios';
import { Modal } from 'bootstrap';

export default {
    data: function () {
        return {
            avatar: '',
            user: { id: 1 },
            user_id: 0,
            validation: '',
            success: false,
            group: 'user',
        }
    },
    mounted: function () {
        this.myModal = new Modal(document.getElementById('editUser'));
    },
    created: function () {
        axios.get('api/v1/user/users/' + this.$route.params.id).then((response) => {
            this.user = response.data;
            this.group = this.user.groups[0] ?? 'superadmin';
        })

    },
    methods: {
        onCancel() {
            this.myModal.hide();
        },
        onSave() {
            this.validation = '';
            this.success = false;
            this.user.groups = [this.group];
            this.user.username = this.user.email;

            axios.put('api/v1/user/users/' + this.$route.params.id, this.user).then(() => {
                this.success = true;
                this.myModal.hide();
            }).catch(error => {
                if (error.response.data.validation) {
                    this.validation = error.response.data.validation
                }
                this.$toast('Wystąpił błąd')
            });
        }
    },

}
</script>