<template>
    <div class="card mb-4">
        <div class="card-body">
            <div class="user-avatar-section">
                <div class=" d-flex align-items-center flex-column">
                   <MagicoAvatar class="m-4" :user_id="user.id" :size="110"></MagicoAvatar>
                    <div class="user-info text-center">
                        <h4 class="mb-2">{{ user.name }}</h4>
                        <span class="badge bg-label-secondary">{{ user.groups?user.groups[0]:'' }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-around flex-wrap my-4 py-3">
                <div class="d-flex align-items-start me-4 mt-3 gap-3">
                    <span class="badge bg-label-primary p-2 rounded"><i class="bx bx-check bx-sm"></i></span>
                    <div>
                        <h5 class="mb-0">20</h5>
                        <span>Ukończonych zadań</span>
                    </div>
                </div>
                <div class="d-flex align-items-start mt-3 gap-3">
                    <span class="badge bg-label-primary p-2 rounded"><i class="bx bx-customize bx-sm"></i></span>
                    <div>
                        <h5 class="mb-0">8</h5>
                        <span>projektów</span>
                    </div>
                </div>
            </div>
            <h5 class="pb-2 border-bottom mb-4">Szczegóły</h5>
            <div class="info-container">
                <ul class="list-unstyled">
                    <!-- <li class="mb-3">
                        <span class="fw-bold me-2">Nazwa użytkownika:</span>
                        <span>{{ user.username }}</span>
                    </li> -->
                    <li class="mb-3">
                        <span class="fw-bold me-2">Email:</span>
                        <span>{{ user.email }}</span>
                    </li>
                    <!-- <li class="mb-3">
                        <span class="fw-bold me-2">Status:</span>
                        <span class="badge bg-label-success">{{ user.status }}</span>
                    </li> -->
                    <li class="mb-3">
                        <span class="fw-bold me-2">Role:</span>
                        <span>{{ user.groups?user.groups[0]:'' }}</span>
                    </li>

                    <li class="mb-3">
                        <span class="fw-bold me-2">Telefon:</span>
                        <span>{{ user.phone_number }}</span>
                    </li>
                </ul>
                <div class="d-flex justify-content-center pt-3">
                    <a href="javascript:;" class="btn btn-primary me-3" data-bs-target="#editUser"
                        data-bs-toggle="modal">Edytuj</a>
                    <!-- <a href="javascript:;" class="btn btn-label-danger suspend-user">Suspended</a> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MagicoAvatar from '../../../components/MagicoAvatar.vue';

export default {
    props: ["user"],
    components: { MagicoAvatar }
}
</script>